import ResourceFile from "./ResouceFile";

class ResourceFileResponse {
	success: boolean;
	errorMessage: string;
	resourceData: ResourceFile | null;
    constructor(success: boolean, errorMessage: string, resourceData: ResourceFile | null){
        this.success = success;
        this.errorMessage = errorMessage;
        this.resourceData = resourceData;
    }
}

export default ResourceFileResponse;