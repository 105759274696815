import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppThunk } from "../../app/store";
import { PageParameter } from "./types";
import { Session } from "inspector";

export interface PageParameterState {
  pageParameter: PageParameter;
}

const initialState: PageParameterState = {
  pageParameter: { pagename: "", back2Page: null },
};

const slice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    // getSessionUser(state: AuthState): void {
    //   state.sessionUser = action.payload;
    // },
    setPagename(state: PageParameterState, action: PayloadAction<string>): void {
      state.pageParameter.pagename = action.payload;
    },
    setBack2Page(state: PageParameterState, action: PayloadAction<string | null>): void {
      state.pageParameter.back2Page = action.payload;
    },
  },
});

export const { reducer } = slice;

export const setPagename = slice.actions.setPagename;
export const setBack2Page = slice.actions.setBack2Page;
