import axios from "axios";
import config from "../constants/config";
import { Application } from "../models/MasterModels";
import ResponseData from "../models/ResponseData";
import { ReportTemplate } from "../views/report/UploadTemplateModel";

const masterUrl = `${config.urlRoot}/master`;
export const loadApplication = async () => {
    let url = `${masterUrl}/loadApplication`;
    let res = await axios.get(url);
    let result: Application[] = [];
    console.log("status: ", res.status);
    if(res.status == 200){
        let resData: ResponseData = res.data;
        result = resData.data;
    }
    return result;
}
