import type { AppThunk } from "../../app/store";
import {
  setPagename as setPagename,
  setBack2Page as setBack2Page,
} from "./slice";

export const setPagenameAction =
  (pagename: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setPagename(pagename));
  };

export const setBack2PageAction =
  (back2Page: string | null): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setBack2Page(back2Page));
  };
