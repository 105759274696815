import { Button, Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { useEffect, useState } from "react";
import classes from "./ModernAppLayout.module.css";
// import styled from "styled-components";
import SideNavModule from "./SideNavModule";
import SideNavMenu from "./SideNavMenu";
import Text from "antd/lib/typography/Text";
import { MessageSquare, Volume2 } from "react-feather";
import { Link, Outlet } from 'react-router-dom';
import { useAppSelector } from "../app/hooks";
import { PageParameterState } from "../store/page/slice";
import { setBack2PageAction } from "../store/page/action";
import { ArrowLeftOutlined } from '@ant-design/icons';

interface IProps {
  children?: React.ReactNode;
}

const menuModules = [
  { id: 1, name: "overview", icon: "BlockOutlined", code: "001" },
  { id: 2, name: "Post", icon: "BookOutlined", code: "002" },
  { id: 3, name: "Counter", icon: "TableOutlined", code: "003" },
  { id: 4, name: "School", icon: "TeamOutlined", code: "004" },
  { id: 5, name: "Security", icon: "SecurityOutlined", code: "005" },
];

const menus = [
  {
    moduleCode: "001",
    moduleMenus: [{ id: 1, name: "Overview", path: "/app/dashboard" }],
  },
  {
    moduleCode: "002",
    moduleMenus: [
      { id: 2, name: "Product", path: "/app/products" },
      { id: 3, name: "User", path: "/app/users" },
      { id: 4, name: "Post", path: "/app/posts" },
      { id: 5, name: "Application", path: "/app/application" },
      { id: 6, name: "Campaign", path: "/app/campaign" },
      { id: 7, name: "Contract", path: "/app/contract" },
      { id: 8, name: "Payment", path: "/app/payment" },
      { id: 11, name: "Report", path: "/app/report" },
      { id: 12, name: "Branch", path: "/app/master/branch" },
      { id: 13, name: "Bank", path: "/app/master/bank" },
      { id: 14, name: "Class", path: "/app/master/class" },
    ],
  },
  {
    moduleCode: "003",
    moduleMenus: [{ id: 5, name: "Counter", path: "/app/counter" }],
  },
  {
    moduleCode: "004",
    moduleMenus: [
      { id: 6, name: "Teacher", path: "/app/teacher" },
      { id: 7, name: "Student", path: "/app/student" },
      { id: 8, name: "Subject", path: "/app/subject" },
      { id: 9, name: "Officer", path: "/app/officers" },
    ],
  },
  {
    moduleCode: "005",
    moduleMenus: [{ id: 10, name: "User", path: "/app/security/user" }],
  },
];

const ModernAppLayout: React.FC<IProps> = (props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<any>({});
  const [pagename, setPagename] = useState<string>("Branch: CM-001");
  const [back2Page, setBack2Page] = useState<string | null>();

  // const { sessionUser } = useAppSelector((state: any) => state.auth);
  const { pageParameter } = useAppSelector((state: any) => state.page)

  console.log(pageParameter);

  const showHandler = () => {
    setShowMenu((prev) => !prev);
  };

  useEffect(() => {
    setPagename(pageParameter.pagename);
  }, [pageParameter.pagename])

  useEffect(() => {
    setBack2Page(pageParameter.back2Page);
  }, [pageParameter.back2Page])

  const handleSelectModule = (moduleCode: string) => {
    console.log("module code", moduleCode);
    const moduleItem = menus.find((module) => module.moduleCode === moduleCode);
    if(moduleItem)
      setMenuItems(moduleItem.moduleMenus);
    if (!showMenu) {
      setShowMenu(true);
    }
  };

  const renderBackIcon = () => {
    if(back2Page){
      return (
        <Link style={{marginRight: 5}} to={back2Page}><ArrowLeftOutlined /></Link>
      )
    }
  }

  console.log("ModernAppLayout: ", props);

  return (
    <div style={{ display: "flex" }}>
      <div className={classes.sideNav}>
        <SideNavModule
          showMenu={showHandler}
          onSelectModule={handleSelectModule}
          menuModules={menuModules}
        />
        {showMenu && <SideNavMenu menuItems={menuItems} />}
      </div>

      <div
        style={{
          backgroundColor: "#f1f2f7",
          width: "100%",
          // overflow: "hidden",
          height: "100%",
          paddingLeft: showMenu ? "285px" : "60px",
        }}
      >
        <div className={classes.notificationBar}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {renderBackIcon()}
            <Text>{pagename}</Text>
          </div>
          <div>
            <ul className={classes.notificationContainer}>
              <li className={classes.notificationItem}>
                <Volume2 strokeWidth={1} />
              </li>
              <li className={classes.notificationItem}>
                <MessageSquare strokeWidth={1} />
              </li>
            </ul>
          </div>
        </div>
        <div
          style={{
            // width: "100%",
            padding: "0px 20px 10px 20px",
          }}
        >
          {/* <div>{props.children}</div> */}
          <div><Outlet></Outlet></div>
        </div>
      </div>
    </div>
  )
};

export default ModernAppLayout;
