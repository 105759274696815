import { Button, DatePicker, Input, Modal, notification, Select, Table, Tag } from "antd";
import { Link, useNavigate } from 'react-router-dom'
import React, { ChangeEventHandler, useEffect, useState } from "react";
import styles from './ReportList.module.css';
import { PlusOutlined, EditTwoTone, DeleteTwoTone, SearchOutlined } from '@ant-design/icons';
import { useAppDispatch } from "../../app/hooks";
import { setPagenameAction, setBack2PageAction } from '../../store/page/action';
import TextArea from "antd/lib/input/TextArea";
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import Search from "antd/lib/input/Search";
import moment from "moment";
import { loadApplication } from "../../services/ApplicationService";
import { Application } from "../../models/MasterModels";
import { createReportTemplateService, getTemplateByAppIdList } from "../../services/ReportTemplateService";
import { CreateReportTemplateModel } from "./UploadTemplateModel";
import ResponseData from "../../models/ResponseData";
// import { AlignCenter } from "react-feather";
const ReportList = () => {
    const [dataList, setDataList] = useState<any>([]);
    const [oriDataList, setOriDataList] = useState<any[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const [applicationOptions, setApplicationOptions] = useState<Application[]>([]);
    const [selectedApplicationId, setSelectedApplicationId] = useState<any[]>([]);
    const [selectedSearchApplicationId, setSelectedSearchApplicationId] = useState<any[]>([]);
    const [templateName, setTemplateName] = useState<string>("");
    const [templateVersion, setTemplateVersion] = useState<string>("");
    const [templateDescription, setTemplageDexcription] = useState<string>("");
    const [selectedCreateApplicationId, setSelectedCreateApplicationId] = useState<any[]>([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const dataSource = [
        {
          id: '1',
          templateName: 'สัญญาเช่า',
          qty: 6,
          createDate: '14/05/2022',
          createBy: 'Khananat Jaroenchai',
          version: '1.0'
        },
        {
          id: '2',
          templateName: 'ใบเสร็จรับเงิน',
          qty: 3,
          createDate: '20/05/2022',
          createBy: 'Weeraya Thain',
          version: '2.0'
        },
    ];
    let columns = [
        {
            title: 'ชื่อรายงาน',
            dataIndex: 'templateName',
            key: 'templateName',
            render: (data: any, record: any) => <Link to={`/app/TemplateReport/upload/${record.id}`}>{data}</Link>
        },
        {
            title: 'เวอร์ชั่น',
            dataIndex: 'version',
            key: 'version',
            render: (data: any, record: any) => <Link to={`/app/TemplateReport/upload/${record.id}`}>{data}</Link>
        },
        {
            title: 'จำนวนไฟล์ต้นแบบ',
            dataIndex: 'countFile',
            key: 'countFile',
            width: 150,
            render: (data: any) => <a>{data}</a>
        },
        // {
        //   title: 'วันที่สร้าง',
        //   dataIndex: 'createDate',
        //   key: 'createDate',
        // },
        // {
        //   title: 'สร้างโดย',
        //   dataIndex: 'createBy',
        //   key: 'createBy',
        // },
        {
          title: 'Action',
          key: 'action',
          render: (data: Application) => {
            //   console.log("render: ", data)
              return (
                  <>
                    <Link to={`/app/TemplateReport/upload/${data.id}`}><EditTwoTone className={styles['action-icons']} /></Link>
                    <Link to={`/app/TemplateReport/upload/${data.id}`}><DeleteTwoTone className={styles['action-icons']} /></Link>
                  </>
              );
          }
        },
    ]
    // const applicationOptionsConst = [
    //     {
    //         label: "นิ่มซี่เส็งเดลิเวอรี่",
    //         value: "01"
    //     },
    //     {
    //         label: "นิ่มรถยก",
    //         value: "02"
    //     },
    //     {
    //         label: "นิ่มเรนเจอร์",
    //         value: "03"
    //     },
    // ]
    const colorCodes: { [key: string]: string } = {
        // "01": "#CD1A08",
        // "02": "#1AAB03",
        // "03": "#0635BE",
        "01": "gold",
        "02": "lime",
        "03": "cyan",
    }

    useEffect(() => {
        setOriDataList([]);
        dispatch(setPagenameAction("Report List"));
        dispatch(setBack2PageAction(null));
        loadApplicationData();
        // getTemplateByAppIdList([1, 2, 3]);
        // (
        //     async () => {
        //         await loadApplicationData();
        //     }
        // )();
    }, [])

    useEffect(() => {
        setDataList(oriDataList);
    }, [oriDataList])

    useEffect(() => {
        if(applicationOptions && applicationOptions.length > 0){
            setSelectedSearchApplicationId([`${applicationOptions[0].id}`]);
        }
    }, [applicationOptions])

    useEffect(() => {
        (
            async () => {
                let result: any[] = await getTemplateByAppIdList(selectedSearchApplicationId);
                if(result){
                    setOriDataList(result);
                }else{
                    setOriDataList([]);
                }
            }
        )()
    }, [selectedSearchApplicationId])

    const showModal = () => {
      setVisible(true);
    };

    const loadApplicationData = async () => {
        let loadedApplicationList: Application[] = await loadApplication();
        console.log("loadedApplicationList: ", loadedApplicationList);
        if(loadedApplicationList){
            setApplicationOptions(loadedApplicationList);
        }else{
            setApplicationOptions([]);
        }
    }

    const createReportTemplate = async () => {
        if(!validateBeforeCreate()){
            return ;
        }
        let data: CreateReportTemplateModel = {
            id: -1,
            applicationId: selectedCreateApplicationId,
            currentStatus: "DRAFT",
            templateName: templateName,
            templateDescription: templateDescription,
            uuid: '',
            version: templateVersion
        };
        console.log("createReport: ", data);
        let resData:ResponseData | null = await createReportTemplateService(data);
        if(resData){
            if(!resData.success){
                notification.error({
                    message: `บันทึกไม่สำเร็จ`,
                    description: resData.message
                });
                return;
            }
            let newReportData: CreateReportTemplateModel = resData.data;
            notification.success({
                message: `บันทึกสำเร็จ`,
                description: resData.message
            });
            setVisible(false);
            navigate(`/app/TemplateReport/upload/${newReportData.id}`);
        }
        // navigate(`/app/TemplateReport/upload/${1}`);
        
    }
    const validateBeforeCreate = () => {
        if(!templateName || templateName.trim() == ""){
            notification.error({
                message: `ข้อมูลไม่ครบ`,
                description: "กรุณากรอกชื่อรายงาน"
            })
            return false;
        }
        if(!selectedCreateApplicationId || selectedCreateApplicationId.length < 1){
            notification.error({
                message: `ข้อมูลไม่ครบ`,
                description: "กรุณาเลือก Application ที่เกี่ยวข้องอย่างน้อย 1 รายการ"
            })
            return false;
        }

        return true;
    }

    const handleOk = () => {
        setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        setTimeout(async() => {
            await createReportTemplate();
            setConfirmLoading(false);
            // navigate(`/app/TemplateReport/upload/${1}`)
        }, 500);
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
    };

    const renderOption = () => {
        let children: React.ReactNode[] = []
        for (let i = 0; i < applicationOptions.length; i++) {
          children.push(<Select.Option key={`option_${i}`} value={`${applicationOptions[i].id}`}>{applicationOptions[i].name}</Select.Option>);
        }
        return children;
    }

    const tagRender = (props: CustomTagProps) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
          event.preventDefault();
          event.stopPropagation();
        };
        let code: string = value;
        return (
          <Tag
            color={colorCodes[`${code}`]}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3 }}
          >
            {label}
          </Tag>
        );
    };

    const onSearch = (value: string) => {
        let resultList = oriDataList.filter((curr: any) => {
            return curr.name.toLowerCase().includes(value.toLowerCase());
        })
        setDataList(resultList)
        //   console.log("search: ", value);
    }

    const onSearchClicked = () => {
        getTemplateByAppIdList(selectedSearchApplicationId);
    }

    const [fromValue, setFromValue] = useState<any>(moment());
    const [toValue, setToValue] = useState<any>(moment());

    const dateRangeChanged = () => {

    }
    const searchApplicationIdChanged = (value: any) => {
        // console.log("changed: ",  value);
        setSelectedSearchApplicationId(value)
    }
    const createApplicationIdChanged = (value: any) => {
        console.log("changed: ",  value);
        setSelectedCreateApplicationId(value)
    }
    return (
        <div className={styles['main-container']}>
            <div className={styles['search-container']}>
                <div className={styles['search-component']}>
                    {/* <div style={{marginRight: 5}}>
                        <DatePicker.RangePicker defaultValue={[moment(), moment()]} format={"DD/MM/YYYY"} value={[fromValue, toValue]} onChange={dateRangeChanged} />
                    </div> */}
                    <div>
                        <Select
                            mode="multiple"
                            showArrow
                            // tagRender={tagRender}
                            style={{ width: 300, marginBottom: 5 }}
                            placeholder={"ทุกแอพพลิเคชั่น"}
                            value={selectedSearchApplicationId}
                            onChange={searchApplicationIdChanged}
                            filterOption={(input, option) =>{
                                return (
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) || 
                                    (option!.value as unknown as string).toLowerCase().includes(input.toLowerCase())
                                )
                            }}
                        >
                            {renderOption()}
                        </Select>
                    </div>
                </div>
                <div className={styles['search-component']}>
                    <Button onClick={onSearchClicked} type={"primary"} icon={<SearchOutlined />}>ค้นหา</Button>
                </div>
            </div>
            <div className={styles['list-container']}>
                <div className={styles['search-component']}>
                    <div className={styles['search-text-container']}>
                        <Search placeholder="ชื่อรายงาน" onSearch={onSearch} enterButton />
                    </div>
                    <div className={styles['button-container']}>
                        <Button onClick={() => {setVisible(true)}} type={"primary"} icon={<PlusOutlined />}>
                            สร้างรายงานใหม่
                        </Button>
                    </div>
                </div>
                <div className={styles['table-container']}>
                    <Table style={{width: '100%'}} columns={columns} dataSource={dataList}>

                    </Table>
                </div>
            </div>

            <Modal
                title="เพิ่มเอกสารใหม่"
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <div style={{marginRight: 5}} className={styles['report-param-component']}>
                    <Input 
                        style={{marginBottom: 5}} 
                        placeholder="ชื่อรายงาน" 
                        value={templateName} 
                        onChange={(value: any) => { setTemplateName(value.target.value) }}/>
                    <Input 
                        style={{marginBottom: 5}} 
                        placeholder="เวอร์ชั่น" 
                        value={templateVersion} 
                        onChange={(value: any) => { setTemplateVersion(value.target.value) }}/>
                    <Select
                        mode="multiple"
                        showArrow
                        tagRender={tagRender}
                        // defaultValue={['gold', 'cyan']}
                        style={{ width: '100%', marginBottom: 5 }}
                        placeholder={"เลือกแอพพลิเคชั่น"}
                        value={selectedCreateApplicationId}
                        onChange={createApplicationIdChanged}
                        filterOption={(input, option) =>{
                            return (
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) || 
                                (option!.value as unknown as string).toLowerCase().includes(input.toLowerCase())
                            )
                        }}
                    >
                        {renderOption()}
                    </Select>
                    <TextArea 
                        value={templateDescription} 
                        showCount 
                        maxLength={100} 
                        style={{ height: 120, width: "100%" }} 
                        placeholder="คำอธิบาย" 
                        onChange={(value: any) => { setTemplageDexcription(value.target.value) }}/>
                </div>
            </Modal>

        </div>
    );
}

export default ReportList