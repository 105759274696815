import React, { useEffect, useState } from "react";
import ResourceFileResponse from "../../models/ResourceFileResponse";
import { UploadFile } from 'antd/lib/upload/interface';
import ResourceFile from "../../models/ResouceFile";
import styles from './UploadTemplate.module.css';
import ReportUpload from "../../components/input/ReportUpload";
import axios from "axios";
import type { AxiosResponse }  from "axios";
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import configs from "../../constants/config";
import { Button, Form, Input, notification, Select, Tag } from "antd";
import { CreateReportTemplateModel, ReportMergedTemplate, ReportTemplateDTO, ReportTemplateParameter, TemplateFiles } from "./UploadTemplateModel";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { setPagenameAction, setBack2PageAction } from '../../store/page/action';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Application } from "../../models/MasterModels";
import { loadApplication } from "../../services/ApplicationService";
import { changeStatusService, getTemplateById } from "../../services/ReportTemplateService";
import ResponseData from "../../models/ResponseData";

const UploadTemplate = (props: any) => {

//   function callback(key: any) {
//     console.log(key);
//   }
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [idcardResource, setIdcardResource] = useState<ResourceFile | null>(null);
//   const [previewSource, setPreviewSource] = useState<any>("http://www.africau.edu/images/default/sample.pdf");
  const [previewSource, setPreviewSource] = useState<any>("/assets/defaule_preview.pdf");
  const [form] = Form.useForm();
  const userId = Form.useWatch('userId', form);
  const formFile = Form.useWatch('userId', form);
  const [templateName, setTemplateName] = useState<string>("");
  const [templateVersion, setTemplateVersion] = useState<string>("");
  const [templateDescription, setTemplageDexcription] = useState<string>("");
  const [selectedCreateApplicationId, setSelectedCreateApplicationId] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>("CANCEL");
//   const [previewSource, setPreviewSource] = useState<string>("https://www.matichon.co.th/article");
  
  const [parameters, setParameters] = useState<any>([]);
  const [iframeRef, setIFrameRef] = useState<HTMLIFrameElement | null>();
  const [mode, setMode] = useState<"add"|"update">();
  const [applicationOptions, setApplicationOptions] = useState<Application[]>([]);
  const [currentTemplateData, setCurrentTemplateData] = useState<CreateReportTemplateModel>();
  const [currentStatus, setCurrentStatus] = useState<string>("DRAFT");
  const [fileList, setFileList] = useState<TemplateFiles[]>([]);
  const { TextArea } = Input;

  const loadApplicationResource = async(applicationId: number) => {

  }

  useEffect(() => {
    dispatch(setPagenameAction("Upload Image"));
    dispatch(setBack2PageAction("/app/TemplateReport"));
    loadApplicationData();
    loadTemplateById();
  }, [])

  const loadTemplateById = async() => {
    let template:CreateReportTemplateModel | null = await getTemplateById(id);
    if(template != null){
        setCurrentTemplateData(template);
    }else{
        navigate(`/app/TemplateReport/`);
    }
  }

  useEffect(() => {
    if(currentTemplateData){
        setTemplateName(currentTemplateData.templateName);
        setTemplateVersion(currentTemplateData.version);
        setTemplageDexcription(currentTemplateData.templateDescription);
        setCurrentStatus(currentTemplateData.currentStatus);
        let reCreatedSelected: string[] = []
        if(currentTemplateData && currentTemplateData.applicationId){
            for(let i = 0; i < currentTemplateData.applicationId.length; i++) {
                reCreatedSelected.push(currentTemplateData.applicationId[i].toString())
            }
        }
        setSelectedCreateApplicationId(reCreatedSelected);
        setFileList(currentTemplateData.templateFiles == null ? [] : currentTemplateData.templateFiles);
        // setTem
    }
  }, [currentTemplateData])

  const onReportUploaded = (uploadStatus: string, responseData: any, file: UploadFile<any>) => {
      if(uploadStatus == 'success'){
        //   console.log("resourceFile: ", resourceFile);
        //   let previewData = 'data:application/pdf;base64,' + resourceFile.data.previewReport;
        //   console.log("previewReport: ", previewData);
        //   new Blob(previewData, { type: 'application/pdf', title: 'testpdf' })
        // let mergedData: ReportTemplateDTO = responseData.data;
        console.log("responseData: ", responseData);
        let reportTemplateParameterList: Array<ReportTemplateParameter> = [];
        let reportTemplateDTOList: Array<ReportTemplateDTO> = responseData;
        for(let i = 0; i < reportTemplateDTOList.length; i++){
            let templateDTO = reportTemplateDTOList[i];
            reportTemplateParameterList = [...reportTemplateParameterList, ...templateDTO.reportTemplateParameterList];
        }
        setParameters(reportTemplateParameterList);
        // loadPreviewData(uploadStatus, responseData, file);
        //   let previewData = resourceFile.data.data.previewReport;
        //   setPreviewSource(previewData);
        // 'data:application/pdf;base64,' 

      }
  }

  const loadPreviewData = (uploadStatus: string, responseData: any, file: UploadFile<any>) => {
    let formElement: any = document.getElementById("hiddenForm");
    let form: HTMLFormElement = formElement;
    let hiddenUserId: HTMLElement | null = document.getElementById("hiddenUserId");
    let hiddenFile: HTMLElement | null = document.getElementById("hiddenFile");
    let hiddenPdfFile: HTMLElement | null = document.getElementById("hiddenPdfFile");
    const formData = new FormData();
    let mergedData: ReportMergedTemplate = responseData.data;
    
    const httpConfig = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (event: any) => {

        }
    };

    let uploadFile: any = file;
    // console.log("file: ", file.url);
    
    if(hiddenUserId){
        hiddenUserId.setAttribute("value", "1");
    }
    // if(hiddenFile){
    //     hiddenFile.setAttribute("value", responseData.data.sourceBase64);
    // }
    if(hiddenPdfFile){
        hiddenPdfFile.setAttribute("value", mergedData.mergedPreviewBase64);
    }
    // formData.append("report", uploadFile);
    // formData.append("userId", "1");
    // console.log("form.enctype: ", form.enctype);
    // let uploadPath = `${configs.urlRoot}/report/previewBeforeUpload`;
    
    form.submit();
    // var request = new XMLHttpRequest();
    // request.open("POST", uploadPath);
    // request.send(formData);
    // console.log("send request");
    // axios.post(uploadPath, formData, httpConfig).then((res: any) => {
    //     console.log("post: ", res);
    //     let uint8Data = new Uint8Array(res.data);
    //     var decoder = new TextDecoder('utf8');
    //     var b64encoded = btoa(decoder.decode(uint8Data));
    //     console.log("conData: ", new Uint8Array(res.data))
    //     console.log("b64: ", b64encoded)
    //     // setPreviewSource('data:application/pdf;base64,' + b64encoded);

    //     // fileToDataUri(res.data)
    //     // .then(dataUri => {
    //     //     setPreviewSource(dataUri)
    //     // })
    //     // setPreviewSource(res);
    // }).catch((err: Error) => {
    //     console.log("err: ", err);
    //     console.log("err stack: ", err.stack);
    //     // const error = new Error('Some error');
    // });
  }

  const previewCallback = (uploadStatus: string, pdfBase64: string) => {
    let formElement: any = document.getElementById("hiddenForm");
    let form: HTMLFormElement = formElement;
    let hiddenUserId: HTMLElement | null = document.getElementById("hiddenUserId");
    let hiddenPdfFile: HTMLElement | null = document.getElementById("hiddenPdfFile");
    const formData = new FormData();
    // let mergedData: ReportMergedTemplate = responseData.data;
    
    const httpConfig = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (event: any) => {

        }
    };

    // let uploadFile: any = file;
    // console.log("file: ", file.url);
    
    if(hiddenUserId){
        hiddenUserId.setAttribute("value", "1");
    }
    if(hiddenPdfFile){
        hiddenPdfFile.setAttribute("value", pdfBase64);
    }
    // formData.append("report", uploadFile);
    // formData.append("userId", "1");
    // console.log("form.enctype: ", form.enctype);
    // let uploadPath = `${configs.urlRoot}/report/previewBeforeUpload`;
    
    form.submit();
  }

  const renderParams = () => {
    let result: any = [];
    const parameterDict: any = []; 
    result = parameters.map((param: any, index: number) => {
        if(!parameterDict[`${param.parameterName}`]){
            parameterDict[`${param.parameterName}`] = true;
            return (
                <Input key={`param${index}`} style={{marginBottom: 5}} placeholder={param.parameterName} value={param.parameterValue}/>
                // <div key={`param${index}`}>{param.parameterName}: {param.parameterType}</div>
            );
        }
    })
    return result;
  }

  useEffect(() => {
      if(applicationOptions && applicationOptions.length > 0){
          if(currentTemplateData && currentTemplateData.applicationId){
            console.log("currentTemplateData eff: ", currentTemplateData)
            let reCreatedSelected: string[] = []
            for(let i = 0; i < currentTemplateData.applicationId.length; i++) {
                reCreatedSelected.push(currentTemplateData.applicationId[i].toString())
            }
            setSelectedCreateApplicationId(reCreatedSelected);
          }
      }
  }, [applicationOptions])

  const loadApplicationData = async () => {
      let loadedApplicationList: Application[] = await loadApplication();
      console.log("loadedApplicationList: ", loadedApplicationList);
      if(loadedApplicationList){
          setApplicationOptions(loadedApplicationList);
      }else{
          setApplicationOptions([]);
      }
  }

  const tagRender = (props: CustomTagProps) => {
      const { label, value, closable, onClose } = props;
      const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
      };
      let code: string = value;
      return (
        <Tag
        //   color={colorCodes[`${code}`]}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          {label}
        </Tag>
      );
  };

  const statusOptions = [
      {
          label: 'DRAFT',
          value: 'DRAFT'
      },
      {
          label: 'TRIAL',
          value: 'TRIAL'
      },
      {
          label: 'PUBLISH',
          value: 'PUBLISH'
      },
      {
          label: 'CANCEL',
          value: 'CANCEL'
      },
  ]
  const renderOption = () => {
    let children: React.ReactNode[] = []
    for (let i = 0; i < statusOptions.length; i++) {
        children.push(<Select.Option className={styles[statusOptions[i].value]} key={`option_${i}`} value={statusOptions[i].value}><span className={styles[`select-option-${statusOptions[i].value}`]}>{statusOptions[i].label}</span></Select.Option>);
    }
    return children;
  }
  const renderApplicationOption = () => {
    let children: React.ReactNode[] = []
    for (let i = 0; i < applicationOptions.length; i++) {
      children.push(<Select.Option key={`option_${i}`} value={`${applicationOptions[i].id}`}>{applicationOptions[i].name}</Select.Option>);
    }
    return children;
  }
  const createApplicationIdChanged = (value: any) => {
      setSelectedCreateApplicationId(value)
  }
  const changeStatus = async (value: string) => {
    console.log("New Status: ", value);
    if(currentTemplateData){
        let res:AxiosResponse<any, any> = await changeStatusService(value, currentTemplateData.id);
        if(res.status == 200){
            let resData: ResponseData = res.data;
            if(!resData.success){
                notification.error({message: resData.message});
                return;
            }
            // setCurrentStatus(value);
            loadTemplateById();
            notification.success({message: resData.message})
            
        }else{
            notification.error({message: "ไม่สามารถเปลี่ยนสถานะแบบร่างเอกสารได้"})
        }
    }
  }
  const getColorStatus = () => {
      if(currentStatus == "PUBLISH"){
          return "green";
      }else if(currentStatus == "CANCEL"){
          return "red";
      }else{
          return "black";
      }
  }
  return (
      <div className={styles['main-container']}>
          <div className={styles['content']}>
            <div className={styles['upload-container']}>
                <div className={styles['upload-button']}>
                    <ReportUpload fileList={fileList} preview={previewCallback} onUploaded={onReportUploaded} reportTemplateId={id} filename={"รายงาน"}/>
                </div>
                <div className={styles['action-container']}>
                    <div className={styles['action-button']}>
                        <div className={styles['save-btn-container']}>
                            <Button type={"primary"}>บันทึก</Button>
                        </div>
                        <div className={styles['status-container']}>
                            <span className={styles['status-container-span']} style={{color: getColorStatus()}}>{currentTemplateData ? currentTemplateData.currentStatus : ""} <ArrowRightOutlined style={{color: "black"}} /></span>
                            <Select
                                showArrow
                                // tagRender={tagRender}
                                style={{}}
                                placeholder={"เลือกสถานะ"}
                                onChange={changeStatus}
                                value={currentStatus}
                            >
                                {renderOption()}
                            </Select>
                        </div>
                    </div>
                    <div className={styles['report-param']}>
                        <div style={{marginRight: 5}} className={styles['report-param-component']}>
                            <Input style={{marginBottom: 5}} placeholder="ชื่อรายงาน" value={templateName}/>
                            <Input style={{marginBottom: 5}} placeholder="เวอร์ชั่น" value={templateVersion}/>
                            <Select
                                mode="multiple"
                                showArrow
                                tagRender={tagRender}
                                // defaultValue={['gold', 'cyan']}
                                style={{ width: '100%', marginBottom: 5 }}
                                placeholder={"เลือกแอพพลิเคชั่น"}
                                value={selectedCreateApplicationId}
                                onChange={createApplicationIdChanged}
                                filterOption={(input, option) =>{
                                    return (
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) || 
                                        (option!.value as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    )
                                }}
                            >
                                {renderApplicationOption()}
                            </Select>
                            <TextArea value={templateDescription} showCount maxLength={100} style={{ height: 120, width: "100%" }} placeholder="คำอธิบาย"/>
                        </div>
                        <div className={styles['report-param-component']}>
                            {renderParams()}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles['preview-container']}>
                <form action={`${configs.urlRoot}/report/readPDF/Preview.pdf`} method="post" id="hiddenForm" target="preview_frame">
                    {/* <input type="hidden" name="userId" id="hiddenUserId" />
                    <input type="hidden" name="report" id="hiddenFile" /> */}
                    <input type="hidden" name="pdfFile" id="hiddenPdfFile" />
                </form>
                <iframe title={"ตัวอย่างรายงาน"} name="preview_frame" style={{width: "95%", minHeight: '600px', height: '100%'}} src={previewSource} ref={(ref) => {setIFrameRef(ref)}}></iframe>
            </div>
          </div>
      </div>
  
  );
}

export default UploadTemplate;
