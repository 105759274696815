class Configs {
    // urlRoot: string = "http://localhost:8080";
    // urlRoot: string = `https://test6.nimtransport.com/nimrental-api`;
    // urlRoot: string = "https://api04.nimsoft.net"; // Prod
    urlRoot: string = "https://api6.nimsoft.xyz"; // Dev
    // urlRoot: string = "https://app06.nimsoft.xyz/api";
    getResourceUrl = (resourceId: number) => {
        return `${this.urlRoot}/file/getResource/${resourceId}`;
    }
    getReportTemplateUrl = (reportTemplateId: number) => {
        return `${this.urlRoot}/report/getReport/${reportTemplateId}`;
    };
}

export default new Configs();